var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.e._id)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.isHome)?_c('base-edit-dialog-date-time',{attrs:{"value":_vm.e.startDate,"id":_vm.e._id,"label":"Beginn"},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'startDate', value, 'DateTime');
}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"label":"Beginn","value":_vm._f("dateformat")(_vm.e.startDate),"readonly":""}})]},proxy:true}],null,false,3201677557)}):_c('v-text-field',{attrs:{"label":"Beginn","value":_vm._f("dateformat")(_vm.e.startDate),"disabled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.isHome)?_c('base-edit-dialog-hallensuche',{attrs:{"value":_vm.venue,"id":_vm.e._id,"label":"Halle","allownew":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'venue', value, 'UUID');
}}}):_c('v-text-field',{attrs:{"label":"Halle","value":_vm.venue,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.isHome)?_c('base-edit-dialog',{attrs:{"value":_vm.e.internalComment,"id":_vm.e._id,"label":"Anmerkungen","textarea":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'internalComment', value);
}}}):_c('v-textarea',{attrs:{"label":"Anmerkungen","value":_vm.e.internalComment,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.isMaster)?_c('base-edit-dialog',{attrs:{"value":_vm.e.publicComment,"id":_vm.e._id,"label":"öffentliche Bemerkungen","textarea":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.savefield(id, 'publicComment', value);
}}}):_c('v-textarea',{attrs:{"label":"öffentliche Bemerkungen","value":_vm.e.publicComment,"disabled":""}})],1),_vm._l((_vm.e.teams),function(t){return _c('v-col',{key:t.team._id,attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"readonly":"","disabled":!_vm.isTeam(t),"label":("Neutralverzicht " + (t.team.name))},on:{"click":function($event){return _vm.switchneutral(t)}},model:{value:(t.neutralwaiver),callback:function ($$v) {_vm.$set(t, "neutralwaiver", $$v)},expression:"t.neutralwaiver"}})],1)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }